import React from 'react'
import './SeminarIcon.css'

function SeminarIcon() {





  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsVectornator="http://vectornator.io"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 3370.82 2384.24"
      id='seminar_icon'
    >
      <g vectornatorLayerName="Layer 1">

        <path id='small_dots' stroke-width="20"
        d='M2579.28 49.151c-11.01 0-19.93 7.765-19.93 17.344 0 9.578 8.92 17.343 19.93 17.343 11 0 19.93-7.765 19.93-17.343 0-9.579-8.93-17.344-19.93-17.344z M2778.56 222.586c-22.01 0-39.85 15.53-39.85 34.687 0 19.157 17.84 34.687 39.85 34.687 22.02 0 39.86-15.53 39.86-34.687 0-19.157-17.84-34.687-39.86-34.687z'
        ></path>
        <path id='background_si' stroke-width="20"
        d='M1664.09 66.495c-615.5 0-1114.466 507.62-1114.467 1133.805 0 626.18 498.967 1133.8 1114.467 1133.8 615.51 0 1114.47-507.62 1114.47-1133.8 0-626.185-498.96-1133.805-1114.47-1133.805z'
        ></path>
        <path 
        id="yellow_dots_s_1"
         stroke-width="20"
         d="M2698.85 31.808c-44.03 0-79.72 31.06-79.72 69.374 0 38.314 35.69 69.374 79.72 69.374 44.02 0 79.71-31.06 79.71-69.374 0-38.314-35.69-69.374-79.71-69.374zM2597.84 1935.73c-33.27 0-60.24 27.44-60.24 61.29 0 33.85 26.97 61.29 60.24 61.29s60.24-27.44 60.24-61.29c0-33.85-26.97-61.29-60.24-61.29zM609.865 1476.09c-66.541 0-120.483 54.87-120.483 122.57 0 67.69 53.942 122.57 120.483 122.57s120.483-54.88 120.483-122.57c0-67.7-53.942-122.57-120.483-122.57z"
         ></path>
        <path id='more_dots' stroke-width='20'
        d='M715.288 1169.65c-41.588 0-75.302 34.3-75.302 76.61s33.714 76.61 75.302 76.61c41.588 0 75.302-34.3 75.302-76.61s-33.714-76.61-75.302-76.61zM2552.66 648.717c-24.96 0-45.18 20.579-45.18 45.965 0 25.386 20.22 45.965 45.18 45.965 24.95 0 45.18-20.579 45.18-45.965 0-25.386-20.23-45.965-45.18-45.965z'
        ></path>

        <path
          fill="#472b29"
          d="M1748.01 415.828c10.12 0 18.67 8.703 18.67 18.999v123.83c0 10.296-8.55 18.998-18.67 18.998-10.12 0-18.68-8.702-18.68-18.998v-123.83c-.03-10.296 8.53-18.999 18.68-18.999m0-42.9c-33.46 0-60.84 27.854-60.84 61.899v123.83c0 34.044 27.38 61.899 60.84 61.899 33.46 0 60.84-27.855 60.84-61.899v-123.83c0-34.045-27.38-61.899-60.84-61.899z"
        ></path>
        <path
          fill="#fffef4"
          d="M1221.08 1423.99c-26.45 0-47.96-21.88-47.96-48.78V627.022c0-26.905 21.51-48.784 47.96-48.784h1036.63c26.45 0 47.96 21.879 47.96 48.784v748.188c0 26.9-21.51 48.78-47.96 48.78H1221.08z"
        ></path>
        <path
          fill="#472b29"
          d="M2257.71 599.688c14.55 0 26.87 12.502 26.87 27.334v748.218c0 14.8-12.29 27.33-26.87 27.33H1221.08c-14.55 0-26.87-12.5-26.87-27.33V627.022c0-14.801 12.29-27.334 26.87-27.334h1036.63m0-42.901H1221.08c-37.99 0-69.04 31.594-69.04 70.235v748.218c0 38.61 31.05 70.2 69.04 70.2h1036.66c37.96 0 69.01-31.59 69.01-70.23V627.022c0-38.641-31.05-70.235-69.04-70.235z"
        ></path>
        <path
          fill="#afabd4"
          d="M1212.28 627.267c-38.22 0-69.28-31.593-69.28-70.48 0-38.886 31.06-70.479 69.28-70.479h1084.35c38.22 0 69.28 31.593 69.28 70.479 0 38.887-31.06 70.48-69.28 70.48H1212.28z"
        ></path>
        <path
          fill="#472b29"
          d="M2296.63 507.758c26.57 0 48.19 22.002 48.19 49.029 0 27.028-21.62 49.03-48.19 49.03H1212.28c-26.57 0-48.19-22.002-48.19-49.03 0-27.027 21.62-49.029 48.19-49.029h1084.35m0-42.901H1212.28c-49.7 0-90.36 41.369-90.36 91.93 0 50.562 40.66 91.93 90.36 91.93h1084.35c49.7 0 90.36-41.368 90.36-91.93 0-50.561-40.66-91.93-90.36-91.93z"
        ></path>
        <path
          fill="#fddbbc"
          d="M1197.22 608.881c-63.24-.039-114.53 52.107-114.53 116.444 0 64.338 51.29 116.483 114.53 116.445 63.24.038 114.53-52.107 114.53-116.445 0-64.337-51.29-116.483-114.53-116.444z"
        ></path>
        <path
          fill="#472b29"
          d="M1197.22 630.331c51.48 0 93.38 42.625 93.38 94.994 0 52.37-41.9 94.994-93.38 94.994s-93.37-42.624-93.37-94.994c0-52.369 41.89-94.994 93.37-94.994m0-42.9c-74.85 0-135.54 61.746-135.54 137.894 0 76.149 60.69 137.895 135.54 137.895s135.54-61.746 135.54-137.895c0-76.148-60.69-137.894-135.54-137.894zM1769.52 1629.3c-8.32 0-15.06-6.86-15.06-15.32v-183.86c0-8.46 6.74-15.32 15.06-15.32 8.31 0 15.06 6.86 15.06 15.32v183.86c0 8.46-6.75 15.32-15.06 15.32z"
        ></path>
        <path
          fill="#472b29"
          d="M1580.63 1734.84c-2.62 0-5.24-.68-7.65-2.12-7.17-4.32-9.55-13.66-5.33-20.99l167.38-288.84a14.949 14.949 0 0120.63-5.42c7.17 4.32 9.55 13.66 5.33 20.99l-167.38 288.84c-2.8 4.84-7.83 7.54-12.98 7.54zM1960.99 1734.84c-5.15 0-10.18-2.7-12.98-7.54l-167.38-288.84c-4.25-7.3-1.87-16.67 5.33-20.99 7.14-4.33 16.42-1.94 20.63 5.42l167.39 288.84c4.24 7.3 1.86 16.67-5.34 20.99a14.79 14.79 0 01-7.65 2.12z"
        ></path>
        <path
          fill="#4fa673"
          d="M1287.79 1868.32c-30.54 0-55.81-25.25-56.35-56.32l-10.24-387.09c-.33-11.64-9.82-21.63-21.24-21.63-5.72 0-11.23 2.36-15.21 6.55-3.98 4.2-6.2 10.52-5.99 16.34l-12.93 385.49c-.72 31.32-26.23 56.66-56.83 56.66-14.49 0-28.05-5.76-38.11-16.24-10.06-10.51-15.42-24.42-15.06-39.16l9.07-388.07c.15-6.31-2.47-12.38-7.11-16.58-3.88-3.49-8.85-5.39-13.97-5.39-1.03 0-2.05.09-3.11.24-2.98.46-5.33.86-7.8.86-30.33 0-54.969-25.06-54.969-55.92v-278.61c.061-.46.512-3.8.542-4.26 2.169-55.53 59.577-175.525 216.117-175.525 0 0 6.42.184 6.96.215 87.11.95 137.44 16.639 164.43 29.632 6.56 2.666 10.6 4.78 13.97 7.324l131 97.964c3.64 2.7 8.01 4.17 12.5 4.17h118.04c30.33 0 54.97 25.07 54.97 55.92 0 30.86-24.64 55.93-54.97 55.93h-136.93c-5.96 0-12.01-1.17-18.55-3.59-.93-.33-1.9-.64-2.92-.83-7.92-1.68-15.06-4.84-21.24-9.46l-96.93-72.51c-3.7-2.78-8.1-4.19-12.5-4.19-3.22 0-6.5.76-9.51 2.29-7.08 3.65-11.57 11.07-11.57 19.16v167.98c0 11.31.63 41.37 1.02 61.38l9.01 511.68c.27 14.77-5.18 28.75-15.33 39.23-10.15 10.57-23.74 16.36-38.26 16.36z"
        ></path>
        <path
          fill="#472b29"
          d="M1195.11 911.146h.06l4.61.092c.81.061 1.57.092 2.23.092 82.71.889 129.94 15.475 155 27.548.91.429 1.81.828 2.74 1.195 3.44 1.349 5.94 2.666 7.71 3.984l131.06 98.023a41.566 41.566 0 0024.97 8.34h118.04c18.71 0 33.92 15.47 33.92 34.47s-15.21 34.48-33.92 34.48H1504.6c-3.43 0-7.1-.77-11.29-2.3-1.93-.71-3.89-1.29-5.88-1.69-4.97-1.04-9.33-2.97-13.01-5.73l-96.96-72.5c-7.38-5.51-16.14-8.33-24.97-8.33-6.5 0-13.01 1.53-19.03 4.62-14.19 7.3-23.14 22.1-23.14 38.28v167.98c0 11.53.64 42.32 1.06 62.52l.3 15.65 8.64 495.29c.16 8.98-3.16 17.44-9.3 23.84-6.15 6.38-14.4 9.9-23.26 9.9-18.79 0-34.94-16.15-35.27-35.64l-10.24-385.74c0-23.68-19.03-43.63-42.32-43.63-11.59 0-22.74 4.87-30.69 13.48-7.08 7.63-11.21 18.14-11.57 28.68l-12.95 387.4c-.45 19.24-16.81 35.51-35.72 35.51-8.77 0-16.96-3.49-23.02-9.8-6.08-6.32-9.3-14.74-9.09-23.66l9.09-388.07c.31-12.65-5.33-24.73-14.63-33.12-7.75-6.96-17.96-10.7-28.23-10.7-2.11 0-4.21.16-6.29.46-1.33.22-2.65.49-3.89.52-18.7 0-33.914-15.47-33.914-34.47v-276.86c.181-1.44.361-2.85.452-4.32l.09-1.57c1.232-30.67 39.672-154.224 195.542-154.224m5.18-42.931c-.42 0-.81.122-1.23.122-1.57 0-2.89-.122-4.49-.122-175.78 0-234.882 137.615-237.141 195.355-.151 1.96-.572 3.86-.572 5.85v278.61c0 42.56 34.217 77.4 76.083 77.4 3.74 0 7.32-.58 10.91-1.13l-9.1 388.06c-.99 42.57 32.41 77.41 74.25 77.41 41.83 0 76.9-34.81 77.89-77.41l12.92-386.96c-.03-.46.06-.7.12-.7.09 0 .15.21.15.73l10.24 386.9c.76 42.57 35.61 77.41 77.44 77.41 41.84 0 75.46-34.81 74.7-77.41l-8.7-495.28c-.21-12.02-1.36-62.03-1.36-77.41v-167.98l96.96 72.5c9.04 6.74 19.1 10.97 29.43 13.17 8.07 3.01 16.69 4.91 25.75 4.91h136.93c41.84 0 76.09-34.81 76.09-77.41 0-42.59-34.22-77.4-76.09-77.4h-118.04l-131.06-98.031c-5.45-4.075-11.32-6.986-17.38-9.376-29.45-14.188-82.98-30.705-172.59-31.686-.69.092-1.38-.122-2.11-.122z"
        ></path>
        <path
          fill="#472b29"
          d="M1257.46 1047.08h-90.36c-8.31 0-15.06-6.86-15.06-15.32s6.75-15.32 15.06-15.32h90.36c8.32 0 15.06 6.86 15.06 15.32s-6.74 15.32-15.06 15.32zM1076.74 1414.8c-8.32 0-15.06-6.87-15.06-15.32V1062.4c0-8.46 6.74-15.32 15.06-15.32 8.31 0 15.06 6.86 15.06 15.32v337.08c0 8.45-6.75 15.32-15.06 15.32zM2221.33 1047.08c-8.32 0-15.06-6.86-15.06-15.32V740.647h-111.09c-8.31 0-15.06-6.864-15.06-15.322 0-8.457 6.75-15.321 15.06-15.321h126.15c8.31 0 15.06 6.864 15.06 15.321v306.435c0 8.46-6.75 15.32-15.06 15.32zM2221.33 1261.58c-8.32 0-15.06-6.86-15.06-15.32v-137.89c0-8.46 6.74-15.33 15.06-15.33 8.31 0 15.06 6.87 15.06 15.33v137.89c0 8.46-6.75 15.32-15.06 15.32zM2221.33 1353.51h-811.37c-8.31 0-15.06-6.86-15.06-15.32s6.75-15.32 15.06-15.32h811.37c8.31 0 15.06 6.86 15.06 15.32s-6.75 15.32-15.06 15.32zM1995.42 740.647h-602.41c-8.32 0-15.06-6.864-15.06-15.322 0-8.457 6.74-15.321 15.06-15.321h602.41c8.32 0 15.06 6.864 15.06 15.321 0 8.458-6.74 15.322-15.06 15.322z"
        ></path>
      </g>
    </svg>
  );
}

export default SeminarIcon;
