import React from "react";

function GiftCardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsVectornator="http://vectornator.io"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 3370.82 2384.24"
    >
      <g fill="#dbba4b" vectornatorLayerName="Layer 1">
        <path d="M2027.64 1433.05l717.78-406.35c30.13-17.08 37.6-54.178 15.35-79.059-29.96-33.421-75.68-54.916-127.15-54.916H1320.21c-54.67 0-102.94 24.145-132.65 61.173-20.03 24.881-11.17 60.142 17.81 76.342l720.81 402.81c31.03 17.37 70.43 17.37 101.46 0z"></path>
        <path d="M1926.18 1600.45l-710.14-396.93c-26.1-14.57-60.01 2.28-60.01 29.89v689.91c0 81.34 73.47 147.22 164.18 147.22h1313.41c90.7 0 164.17-65.88 164.17-147.22V1228.7c0-27.68-34.06-44.54-60.17-29.74l-709.98 401.56c-31.03 17.3-70.43 17.3-101.46-.07z"></path>
        <path
          stroke="#fff2f2"
          strokeOpacity="0"
          strokeWidth="91.055"
          d="M839.67 1419.98c12.605-31.65 6.238-66.82-16.251-92.35l-114.157-129.24c-25.563-28.93-30.089-69.73-11.04-103.49 32.928-58.36 88.963-152.846 151.454-237.386 62.491-84.54 136.907-166.532 183.424-215.653 26.92-28.417 68.56-37.831 105.49-24.28l164.97 60.494c32.58 11.967 69.56 5.817 96.53-16.069l340.65-276.138c32.94-26.753 43.92-70.623 27.14-108.386-16.23-36.695-48.36-82.454-111.61-125.386C1458.44 17.626 1156.91-20.349 622.208 703.02c-534.706 723.37-397.575 983.57-199.663 1118.1 63.349 43.06 119.077 56.89 160.643 59.48 42.619 2.56 81.904-21.57 97.259-60.15 38.258-96.33 120.891-304.13 159.223-400.47z"
        ></path>
      </g>
    </svg>
  );
}

export default GiftCardIcon;