import React from "react";
import './Loading.css'

function Loading() {
  return (
    <div className="load_container">


    <h1 className="load_child">Loading</h1>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsVectornator="http://vectornator.io"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 3370.82 2384.24"
      className="Loading_svg"
      >
      <g fill="#fff" vectornatorLayerName="Layer 1">
        <path
        className="Loading_path1"
        d="M1980.66 164.977c-42.27 179.577-316.97 282.193-390.92 504.527-169.05 521.626 475.44 419.016 507.13 427.566-31.69 179.58-147.91 230.88-179.61 230.88-31.69 0 52.83 8.55 63.4 25.66 10.56 17.1 21.13 17.1 42.26 17.1 10.56 0 52.82 0 52.82-8.55 10.57 0 116.22-128.27 137.35-384.81 21.13-247.987 31.7-333.5-116.22-598.59 158.49 171.025 253.57 290.744 200.75 718.31 179.61 0 306.39-136.822 306.39-333.502 10.57-299.295-443.74-487.424-623.35-598.591z"
        vectornatorLayerName="path"
        ></path>
        <path
              className="Loading_path2"
              d="M1632 1362.16c42.26-111.17 221.87-94.07 264.13-34.21 21.13-51.3 105.66-94.06 158.48-94.06 84.53 0 190.18 8.55 232.44 111.17 169.05-85.52 338.09 34.2 338.09 102.61l-464.87 205.23s-31.7-59.86-232.44-42.75c-169.05 17.1-369.79-76.97-475.44-162.48-21.13-42.75 63.39-111.17 179.61-85.51z"
          vectornatorLayerName="path"
          ></path>
        <path
              className="Loading_path3"
              d="M2910.41 1524.63c-42.26-42.75-84.52-85.51-338.09 25.66-200.75 85.51-359.23 162.47-390.92 179.57 10.56 17.11 10.56 34.21 10.56 59.86 0 128.27-190.17 171.03-285.26 171.03h-348.66c-190.18 0-369.79-162.48-369.79-162.48s221.88 76.97 369.79 76.97h348.66s179.61-8.55 179.61-85.52c0-76.96-95.09-85.51-243-85.51-243.01 0-359.23-85.51-359.23-85.51s-190.17-111.17-243-136.82c-137.35-68.41-211.31-102.62-454.312-25.66-147.915 42.76-401.484 136.82-401.484 136.82l327.526 538.74 137.35-68.41c84.523-42.76 169.05-42.76 274.7 0 126.78 51.3 295.83 94.06 422.62 128.26 179.61 51.31 211.3 42.76 338.09-17.1 105.65-51.31 834.66-401.91 908.62-444.67 116.22-68.41 169.05-145.37 116.22-205.23z"
              vectornatorLayerName="path"
              ></path>
      </g>
    </svg>
              </div>
  );
}

export default Loading;
