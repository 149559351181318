import React from "react";
import './RadioMic.css'

function RadioMic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsVectornator="http://vectornator.io"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 3370.82 2384.24"
    >
      <clipPath id="ArtboardFrame">
        <path d="M0 0H3370.82V2384.24H0z"></path>
      </clipPath>
      <g clipPath="url(#ArtboardFrame)" vectornatorLayerName="Layer 2">
        <path
          fill="#f1bc19"
          d="M2074.77 723.567c-7.23 0-13.09 5.944-13.09 13.275 0 7.332 5.86 13.275 13.09 13.275 7.23 0 13.09-5.943 13.09-13.275 0-7.331-5.86-13.275-13.09-13.275z"
          vectornatorLayerName="path"
        ></path>
      
        {/* ======================DOTS============================== */}
        <path id="yellow_dot_1" stroke-width="20"
        d="M1990.55 710.292c37.18 0 67.32 31.812 67.32 71.053s-30.14 71.053-67.32 71.053c-37.18 0-67.32-31.812-67.32-71.053s30.14-71.053 67.32-71.053z"
        ></path>
        <path id="yellow_dot_2" stroke-width="20"
        d="M2127.13 1546.63c-14.46 0-26.18 11.88-26.18 26.55 0 14.66 11.72 26.55 26.18 26.55 14.46 0 26.19-11.89 26.19-26.55 0-14.67-11.73-26.55-26.19-26.55zm-864-199.13c-28.92 0-52.37 23.77-52.37 53.1 0 29.33 23.45 53.1 52.37 53.1 28.92 0 52.36-23.77 52.36-53.1 0-29.33-23.44-53.1-52.36-53.1z"
        ></path>
        <path id="green_dot_1" stroke-width="20"
        d="M2205.68 856.319c-14.46 0-26.18 11.886-26.18 26.55 0 14.663 11.72 26.55 26.18 26.55 14.46 0 26.18-11.887 26.18-26.55 0-14.664-11.72-26.55-26.18-26.55z"
        ></path>
        <path id="green_dot_2" stroke-width="20"
        d="M1394.04 1692.65c-14.46 0-26.18 11.89-26.18 26.55 0 14.67 11.72 26.55 26.18 26.55 14.46 0 26.18-11.88 26.18-26.55 0-14.66-11.72-26.55-26.18-26.55z"
        ></path>
        <path id="brown_dot_1" stroke-width="20"
        d="M1308.95 1214.75c-18.08 0-32.73 14.86-32.73 33.18 0 18.33 14.65 33.19 32.73 33.19 18.07 0 32.72-14.86 32.72-33.19 0-18.32-14.65-33.18-32.72-33.18zm798.55-225.68c-10.85 0-19.64 8.915-19.64 19.91 0 11 8.79 19.92 19.64 19.92 10.84 0 19.63-8.92 19.63-19.92 0-10.995-8.79-19.91-19.63-19.91z"
        ></path>
        <path id="brown_dot_2" stroke-width="20"
        d="M1394.04 1692.65c-14.46 0-26.18 11.89-26.18 26.55 0 14.67 11.72 26.55 26.18 26.55 14.46 0 26.18-11.88 26.18-26.55 0-14.66-11.72-26.55-26.18-26.55z"
        ></path>
            {/* ===============================DOTS==================== */}
        <g vectornatorLayerName="g">
          <path
            fill="#472b29"
            d="M2073.08 1502.39h-661.82c-6.41 0-11.61-4.02-11.61-8.97 0-4.94 5.2-8.96 11.61-8.96h661.82c6.41 0 11.61 4.02 11.61 8.96 0 4.95-5.2 8.97-11.61 8.97zm-330.93 470.59c-6.41 0-11.61-4.02-11.61-8.96v-46.31c0-4.95 5.2-8.97 11.61-8.97 6.41 0 11.61 4.02 11.61 8.97v46.31c0 4.94-5.2 8.96-11.61 8.96z"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#7d85b0"
            d="M1747.93 1816.06c-119.08 0-215.96-79.05-215.96-176.2v-393.95c0-97.15 96.88-176.2 215.96-176.2 119.08 0 215.96 79.05 215.96 176.2v393.95c0 97.15-96.88 176.2-215.96 176.2z"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#472b29"
            d="M1747.93 1082.26c110.12 0 199.71 73.42 199.71 163.67v393.93c0 90.25-89.59 163.66-199.71 163.66s-199.71-73.41-199.71-163.66v-393.93c0-90.25 89.59-163.67 199.71-163.67m0-25.09c-127.72 0-232.22 84.94-232.22 188.76v393.93c0 103.82 104.5 188.76 232.22 188.76s232.22-84.94 232.22-188.76v-393.93c0-103.82-104.5-188.76-232.22-188.76z"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#58589c"
            d="M1546.18 1335.05h91.89c18.83 0 32.58-11.37 37.92-24.44 9.08-22.19-10.31-47.27-36.41-47.27h-93.4"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#472b29"
            d="M1638.09 1344.01h-91.89v-17.93h91.89c14.33 0 23.41-9.78 26.85-18.17 3.64-8.97 1.41-19.28-5.88-26.95-3.06-3.23-9.68-8.66-19.48-8.66h-93.38v-17.93h93.38c14.63 0 28.56 5.83 38.22 16.01 11.57 12.19 15.12 28.63 9.27 42.94-7.62 18.65-26.85 30.69-48.98 30.69z"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#58589c"
            d="M1546.18 1460.54h91.89c18.83 0 32.58-11.36 37.92-24.43 9.08-22.2-10.31-47.28-36.41-47.28h-93.4"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#472b29"
            d="M1638.09 1469.51h-91.89v-17.93h91.89c14.33 0 23.41-9.79 26.85-18.18 3.64-8.96 1.41-19.27-5.88-26.94-3.06-3.23-9.68-8.66-19.48-8.66h-93.38v-17.93h93.38c14.63 0 28.56 5.83 38.22 16.01 11.57 12.19 15.12 28.63 9.27 42.94-7.62 18.64-26.85 30.69-48.98 30.69z"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#58589c"
            d="M1546.18 1586.04h91.89c18.83 0 32.58-11.37 37.92-24.44 9.08-22.19-10.31-47.27-36.41-47.27h-93.4"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#472b29"
            d="M1638.09 1595h-91.89v-17.92h91.89c14.33 0 23.41-9.79 26.85-18.18 3.64-8.97 1.41-19.28-5.88-26.95-3.06-3.23-9.68-8.66-19.48-8.66h-93.38v-17.93h93.38c14.63 0 28.56 5.83 38.22 16.01 11.57 12.2 15.12 28.64 9.27 42.94-7.62 18.65-26.85 30.69-48.98 30.69z"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#58589c"
            d="M1948.94 1263.34h-93.4c-26.1 0-45.49 25.1-36.41 47.27 5.34 13.07 19.09 24.44 37.92 24.44h91.89"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#472b29"
            d="M1948.94 1344.01h-91.89c-22.13 0-41.36-12.04-48.97-30.71-5.86-14.3-2.3-30.74 9.26-42.94 9.64-10.16 23.59-15.99 38.22-15.99h93.38v17.93h-93.38c-9.8 0-16.41 5.43-19.48 8.66-7.27 7.67-9.52 17.98-5.87 26.95 3.43 8.39 12.51 18.17 26.84 18.17h91.89v17.93z"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#58589c"
            d="M1948.94 1388.83h-93.4c-26.1 0-45.49 25.1-36.41 47.28 5.34 13.07 19.09 24.43 37.92 24.43h91.89"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#472b29"
            d="M1948.94 1469.51h-91.89c-22.13 0-41.36-12.05-48.97-30.71-5.86-14.31-2.3-30.75 9.26-42.94 9.64-10.16 23.59-15.99 38.22-15.99h93.38v17.93h-93.38c-9.8 0-16.41 5.43-19.48 8.66-7.27 7.67-9.52 17.98-5.87 26.94 3.43 8.39 12.51 18.18 26.84 18.18h91.89v17.93z"
            vectornatorLayerName="path"
          ></path>
          <g vectornatorLayerName="g">
            <path
              fill="#58589c"
              d="M1948.94 1514.33h-93.4c-26.1 0-45.49 25.1-36.41 47.27 5.34 13.07 19.09 24.44 37.92 24.44h91.89"
              vectornatorLayerName="path"
            ></path>
            <path
              fill="#472b29"
              d="M1948.94 1595h-91.89c-22.13 0-41.36-12.04-48.97-30.71-5.86-14.3-2.3-30.74 9.26-42.93 9.64-10.17 23.59-16 38.22-16h93.38v17.93h-93.38c-9.8 0-16.41 5.43-19.48 8.66-7.27 7.67-9.52 17.98-5.87 26.95 3.43 8.39 12.51 18.18 26.84 18.18h91.89V1595z"
              vectornatorLayerName="path"
            ></path>
          </g>
          <g vectornatorLayerName="g">
            <path
              fill="#58589c"
              d="M2120.59 2049.93c0-45.86-167.12-83.03-373.31-83.03s-373.31 37.17-373.31 83.03h746.62z"
              vectornatorLayerName="path"
            ></path>
            <path
              fill="#472b29"
              d="M2120.59 2062.48h-746.62c-8.99 0-16.26-5.62-16.26-12.55 0-62.75 195.97-95.58 389.55-95.58 193.6 0 389.57 32.83 389.57 95.58.02 6.93-7.25 12.55-16.24 12.55zm-722.91-25.1h699.21c-31.19-27.29-158.56-57.91-349.63-57.91s-318.4 30.6-349.58 57.91z"
              vectornatorLayerName="path"
            ></path>
          </g>
          <path
            fill="#472b29"
            d="M1770.85 1919.05h-44.4c-181.99 0-330.05-113.97-330.05-254.07v-186.51c0-6.94 7.27-12.55 16.25-12.55 8.99 0 16.26 5.61 16.26 12.55v186.51c0 126.25 133.46 228.96 297.54 228.96h44.4c164.06 0 297.54-102.71 297.54-228.96v-188c0-6.93 7.27-12.55 16.26-12.55 8.98 0 16.25 5.62 16.25 12.55v188c0 140.1-148.06 254.07-330.05 254.07z"
            vectornatorLayerName="path"
          ></path>
          <path
            fill="#472b29"
            d="M1840.82 1684.64h-46.45c-6.4 0-11.61-4.01-11.61-8.96 0-4.95 5.21-8.96 11.61-8.96h46.45c6.41 0 11.61 4.01 11.61 8.96 0 4.95-5.2 8.96-11.61 8.96zM1956.93 1684.64h-58.06c-6.41 0-11.61-4.01-11.61-8.96 0-4.95 5.2-8.96 11.61-8.96h58.06c6.41 0 11.61 4.01 11.61 8.96 0 4.95-5.2 8.96-11.61 8.96zM1736.32 1684.64h-197.39c-6.4 0-11.61-4.01-11.61-8.96 0-4.95 5.21-8.96 11.61-8.96h197.39c6.41 0 11.61 4.01 11.61 8.96 0 4.95-5.2 8.96-11.61 8.96z"
            vectornatorLayerName="path"
          ></path>
        </g>
      </g>
      <g
        fill="none"
        strokeLinejoin="miter"
        strokeWidth="84.294"
        vectornatorLayerName="Layer 1"
      >


                {/* ======================Waves============================== */}
                
        <path id="yellow_wave" stroke-width="40" className="invis"
      d="M2251.76 2180.57c361.65-177.91 608.94-534.46 608.94-946.05 0-589.233-506.16-1066.971-1130.46-1066.971S599.773 645.287 599.773 1234.52c0 411.59 247.289 768.14 609.037 946.14"
        ></path>
        <path id="green_wave" stroke-width="40" className="invis"
        d="M2387.15 1879.56c180.39-162.19 293.01-389.2 293.01-641.08 0-492.829-429.62-892.403-959.5-892.403S761.166 745.651 761.166 1238.48c0 251.88 112.621 478.89 293.004 641.08"
        ></path>
        <path id="brown_wave" stroke-width="40" className="invis"
        d="M2253.74 1745.52c141.68-129.29 230.14-310.23 230.14-511 0-392.821-337.44-711.313-753.64-711.313S976.594 841.699 976.594 1234.52c0 200.77 88.456 381.71 230.146 511"
        ></path>


                {/* ======================Waves============================== */}
      </g>
    </svg>
  );
}

export default RadioMic;
