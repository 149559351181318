import React from "react";

function BlogIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsVectornator="http://vectornator.io"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 3370.82 2384.24"
    >
      <g stroke="#dbba4b" vectornatorLayerName="Layer 1">
        <path
          fill="#dbba4b"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="2.97"
          d="M2960.08 2220.08c-2.16-1.16-204.78-111.39-259.75-277.83 187.91-166.55 290.92-384.84 290.92-618.42 0-354.484-241.85-660.175-589.56-801.804 9.43 42.151 14.88 85.465 18.06 129.388 273.15 133.875 457.93 384.846 457.93 672.416 0 211.37-98.58 409.22-277.69 556.94-15.73 12.96-23 33.29-19.02 53.01l1.65 8.03c34.41 146.17 150.88 255.67 234.18 317.71-19.36 3.54-42.93 5.92-71.38 5.92-350.88 0-493.42-145.39-512.04-165.5l-8.12-7.48c-10.62-9.8-24.59-15.06-38.96-15.06-3.75 0-7.55.39-11.3 1.11-69.68 13.79-138.96 20.77-205.91 20.77-338.05 0-633-158.69-789.45-393.1-18.85-1.66-37.65-3.1-56.61-5.65-15.79 11.91-35.32 24.82-56.68 37.89 171.9 280.27 511.88 471.63 902.74 471.63 64.8 0 131.41-5.81 198.36-17.39 55.65 52.51 229.47 183.56 577.97 183.56 158.32 0 219.48-59.21 230.1-71.12 11.36-12.63 16.13-29.58 13.12-46.14-2.95-16.5-13.46-30.85-28.56-38.88z"
        ></path>
        <path
          fill="#dbba4b"
          strokeLinecap="butt"
          strokeWidth="8.486"
          d="M377.069 1341.01c1.369-.74 129.985-70.9 168.735-182.65-111.687-117.45-169.328-268.069-162.73-426.682 10.013-240.721 168.456-442.705 387.828-530.829-7.029 28.405-11.629 57.691-14.84 87.445-172.97 84.585-294.516 250.729-302.639 446.014-5.971 143.53 49.503 280.162 156.271 384.622 9.377 9.17 13.305 23.14 10.286 36.44l-1.247 5.41c-25.445 98.47-100.68 170.13-154.033 210.33 11.894 2.85 26.424 5.02 44.046 5.68 217.342 8.12 309.737-87.31 321.842-100.54l5.241-4.88c6.855-6.42 15.656-9.67 24.555-9.33 2.322.08 4.667.43 6.969 1.01 42.769 10.98 85.484 17.32 126.955 18.87 209.392 7.83 396.572-93.1 500.092-248.65 11.73-.7 23.41-1.24 35.23-2.53 9.45 8.45 21.18 17.67 34.04 27.04-114.39 186.34-330.39 308.42-572.491 299.37-40.134-1.5-81.229-6.99-122.373-16.41-35.954 34.37-147.324 119.34-363.189 111.27-98.066-3.67-134.276-45.29-140.517-53.62-6.678-8.84-9.154-20.46-6.822-31.64 2.295-11.14 9.208-20.64 18.791-25.74z"
        ></path>
        <g
          fill="#dbba4b"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="18.752"
        >
          <path d="M2216.23 1234.25c-96.28-.05-174.35 76.06-174.35 169.96s78.07 170.01 174.35 169.96c96.27.05 174.34-76.06 174.34-169.96s-78.07-170.01-174.34-169.96z"></path>
          <path d="M2474.19 1121.7h-33.49l-47.21-81.25c-8.18-14.09-25.93-24.69-50.55-24.69h-253.43c-25.67 0-42.33 10.6-50.55 24.69l-47.21 81.25h-65.17c0-19.45-16.25-35.31-36.2-35.31h-72.41c-19.95 0-36.21 15.86-36.21 35.31v6.5c-42.11 14.59-72.41 53.36-72.41 99.44v357.55c0 95.1 79 172.15 176.5 172.15h588.34c97.5 0 176.5-77.05 176.5-172.15v-291.33c0-95.1-79-172.16-176.5-172.16zm-620.02 123.6c-29.98 0-54.31-23.73-54.31-52.97s24.33-52.97 54.31-52.97c29.98 0 54.31 23.73 54.31 52.97s-24.33 52.97-54.31 52.97zm362.06 408.19c-141.21 0-255.58-111.52-255.58-249.28s114.37-249.28 255.58-249.28c141.2 0 255.57 111.56 255.57 249.28s-114.37 249.28-255.57 249.28z"></path>
        </g>
        <path
          fill="none"
          strokeLinecap="square"
          strokeLinejoin="miter"
          strokeWidth="56.17"
          d="M1642.78 604.514h-319.56l-191.73 112.733V604.514h-127.83c-35.277 0-63.91-25.252-63.91-56.366V97.218c0-31.115 28.633-56.367 63.91-56.367h639.12c35.28 0 63.91 25.252 63.91 56.367v450.93c0 31.114-28.63 56.366-63.91 56.366z"
        ></path>
        <path
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="56.17"
          d="M1546.91 209.95h-447.38m447.38 112.733h-447.38m257.34 106.269h-255.65"
        ></path>
      </g>
    </svg>
  );
}

export default BlogIcon;
