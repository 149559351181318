import React from "react";
import './Products.css'

function ProductsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsVectornator="http://vectornator.io"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 3370.82 2384.24"
    >
      <g fill="#4fa673" vectornatorLayerName="Layer 1">
        <path id="path" stroke-width="20"
        d="M1898.8 778.785c5.33 1.656 12.44 3.312 17.77 3.312 7.11 0 16-1.656 23.11-4.967 12.44-4.968 23.11-14.903 28.44-28.15 90.65-231.82-37.33-488.478-286.17-572.926-12.44-4.968-28.44-3.312-40.88 1.656-12.44 4.967-23.1 14.902-28.44 28.149-90.64 231.82 39.11 488.478 286.17 572.926zm-524.34 44.708c-23.11 18.215-24.88 49.676-7.11 69.546 49.77 56.3 120.87 89.417 199.07 96.04 8.89 0 17.78 1.656 26.66 1.656 69.32 0 135.09-21.526 188.41-64.578 23.11-18.215 24.88-49.676 7.11-69.546-49.77-56.299-120.87-89.417-199.07-96.04-79.99-6.623-156.41 14.903-215.07 62.922zm760.73-38.084c108.44 4.967 58.66 6.623 87.1 6.623 101.31 0 199.07-28.149 284.38-84.448 108.43-71.202 181.3-177.177 204.41-298.055 3.55-16.558 0-34.773-12.44-49.675-12.45-14.903-26.67-23.182-44.44-26.494-129.75-21.526-263.06 4.968-371.48 76.169-108.42 71.202-181.3 178.833-204.4 299.71-3.56 16.559 0 34.773 12.44 49.676 12.44 14.903 26.66 23.182 44.43 26.494zM759.475 941.059c0 246.721 215.067 447.081 479.905 447.081 30.21 0 53.32-21.53 53.32-49.68 0-246.72-215.07-447.076-479.903-447.076-30.216 0-53.322 21.526-53.322 49.675zm447.905-29.805c5.34 0 10.67 0 16-1.656 28.44-8.279 44.44-36.429 35.55-62.923-21.33-61.266-46.21-120.877-76.43-178.832-12.44-24.838-44.44-34.773-71.1-23.182-26.66 11.591-37.32 41.396-24.88 66.234 28.44 52.988 51.54 107.631 71.1 163.93 5.33 23.182 26.66 36.429 49.76 36.429z"
        ></path>
        <path d="M1719.28 1719.31c30.22 0 53.32-21.52 53.32-49.67v-132.47c0-28.15-23.1-49.68-53.32-49.68H866.12c-30.216 0-53.323 21.53-53.323 49.68v132.47c0 28.15 23.107 49.67 53.323 49.67h316.38c28.44 0 55.1 19.87 56.88 46.37 1.77 28.15-23.11 52.98-53.33 52.98H999.426c-31.994 0-56.877 26.5-53.323 56.3l44.436 347.73c3.555 24.84 26.661 43.05 53.321 43.05H2323.6c26.66 0 49.77-18.21 53.32-44.7l101.32-849.46h129.75c30.21 0 53.32-21.52 53.32-49.67V1123.2c0-28.15-23.11-49.67-53.32-49.67h-618.54c14.22-64.58 21.33-130.815 19.55-197.049 0-28.15-24.89-48.02-55.1-48.02-30.22 0-53.32 23.182-51.55 51.332 1.78 66.234-5.33 130.817-23.1 193.737H1402.9c-28.44 0-55.1 19.87-56.88 46.36-1.78 28.15 23.11 52.99 53.32 52.99h1155.32v99.35H1456.22c-28.44 0-55.1 19.87-56.88 46.36-1.77 28.15 23.11 52.99 53.33 52.99h917.14l-94.2 794.81h-673.64l58.65-447.08h58.66z"></path>
      </g>
    </svg>
  );
}
export default ProductsIcon;
