import React from "react";

function FFCIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsVectornator="http://vectornator.io"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 3370.82 2384.24"
    >
      <g fill="#95562e" vectornatorLayerName="Layer 1">
        <path d="M1747.71 824.492c0 47.595-90.77 317.298-90.77 317.298s-90.77-269.703-90.77-317.298c0-47.595 36.31-79.325 90.77-79.325 54.47 0 90.77 31.73 90.77 79.325zm-181.54 634.598c0-47.59 90.77-317.3 90.77-317.3s90.77 269.71 90.77 317.3c0 47.6-36.3 79.33-90.77 79.33-54.46 0-90.77-31.73-90.77-79.33zm-181.54-547.34c45.39 23.797 272.31 230.04 272.31 230.04s-317.69-71.39-363.08-87.26c-36.31-23.79-54.46-71.388-27.23-111.05 18.15-39.663 81.69-47.595 118-31.73zm544.63 460.08c-45.39-23.79-272.32-230.04-272.32-230.04s317.7 71.39 363.09 87.26c45.38 23.8 54.46 71.39 36.3 111.05-18.15 39.67-90.77 47.6-127.07 31.73zm-635.4-142.78c45.39-23.8 363.08-87.26 363.08-87.26s-226.92 206.25-272.31 230.04c-45.38 23.8-99.85 7.94-127.08-31.73-18.15-39.66 0-87.25 36.31-111.05zm726.17-174.52c-45.39 23.8-363.09 87.26-363.09 87.26s226.93-206.243 272.32-230.04c45.38-23.798 99.84-7.933 127.07 31.73 27.24 39.662 0 87.26-36.3 111.05z"></path>
        <path d="M1656.94 316.812c36.31 0 154.31 7.932 208.78 126.92l63.54 134.852 45.38 95.19 118-15.865 163.39-23.797h36.31c99.85 0 163.38 55.527 199.69 111.055 18.16 31.73 54.47 118.988-27.23 206.245l-118 111.058-72.62 79.32 72.62 79.33 108.92 111.05c81.7 87.26 45.39 174.52 27.24 206.25-27.24 55.53-99.85 111.05-199.7 111.05h-36.31l-163.38-23.79-118.01-15.87-36.3 95.19-63.54 134.85c-54.47 111.06-172.47 126.92-208.78 126.92-36.3 0-154.31-7.93-208.77-126.92L1384.63 1705l-45.38-95.19-118.01 15.87-163.38 23.79h-36.31c-99.848 0-163.388-55.52-199.696-111.05-18.154-31.73-54.462-118.99 27.231-206.25l118.002-111.05 72.613-79.33-72.613-79.32-108.925-111.058c-81.693-87.257-45.385-174.515-27.231-206.245 27.231-55.528 99.848-111.055 199.699-111.055h36.3l163.39 23.797 118 15.865 36.31-95.19 63.54-134.852c54.46-118.988 172.47-126.92 208.77-126.92m0-126.92c-136.15 0-281.39 71.392-344.92 206.245l-54.47 134.852-172.46-23.797h-54.46c-299.546 0-481.088 301.435-281.392 523.548l99.847 111.05-99.847 111.06c-199.696 222.11-18.154 523.54 281.392 523.54h54.46l163.39-23.79 63.54 134.85c63.53 134.85 208.77 206.24 344.92 206.24 136.16 0 281.39-71.39 344.93-206.24l63.54-134.85 163.39 23.79h54.46c299.54 0 481.08-309.37 281.39-523.54l-99.85-111.06 108.93-111.05c190.61-222.113 9.07-523.548-290.47-523.548h-54.46l-163.39 23.797-63.54-134.852c-63.54-134.853-208.77-206.245-344.93-206.245z"></path>
      </g>
    </svg>
  );
}

export default FFCIcon;
